.googleButton{
    width:200px !important; 
    font-size: 14px !important;
    font-weight: 500 !important; 
    font-family: Roboto, sans-serif !important;
}

.googleLogoutButton{
    width:120px !important;
    font-size: 14px !important;
    font-weight: 500 !important; 
    font-family: Roboto, sans-serif !important;
    margin-top:10px;
}

span.gogglebutton {
    background: url('/../../images/btn_google_light_normal_ios.svg') no-repeat left center;
    display: inline-block;
    vertical-align: middle;
}

.g_id_signin {
    width: '200px';    
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.54);
    border-radius: 2px;
    border: 1px solid transparent;
    box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
    font-size: 14px; 
    font-weight: 500; 
    font-family: Roboto, sans-serif;
    padding-left: 23px;
    margin-bottom: 5px;
}