.welcomeHeading {
    font-size: 1.5rem;
    color: #467abd;
    margin: 0 0 20px 0;
}
.tableStyle {
    width: 900px;
    max-height: 195px;
    overflow-y: auto;
}
.tableStyle:hover{
    cursor:pointer;
}

.heading{
    font-weight: 600;
}
.linkButton {
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #8fb91c;
    border:none;
}
.bold{
    font-weight:bold;
    display:block;
}
.contactUs {
    width: 100%;
    text-align: right;
    padding: 20px;
}
.actionButtonContainer {
    width: 50%;
    padding: 10px;
}

.welcomeContainer{
    padding: 10px;
}

.flagButton {
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    width: 200px;
    height: 100px;

    &:active,
    &:hover,
    &:focus {
        border: none;
    }

    .flag {
        width: 100%;
        height: 100%;
    }

    @media screen and (max-width: 480px) {
        width: 100px;
        height: 50px;
    }
}


.animate {
    animation-duration: .8192s;
    animation-fill-mode: backwards;
    transform-style: preserve-3d;
}

/** intro */
@keyframes intro {
    from {
        opacity: 0;
        transform: perspective(500px) translate3d(0, 0, -50px);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.popIn {
    animation: intro 1s ease-out;
}
