@import "~office-ui-fabric-react/dist/sass/References.scss";

body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}

#root {
    width: 100%;
    min-height: 100vh;
    display: flex;
}

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.fullWidth {
    width: 100%;
}
.submitWrapper {
    width: 100%;
    margin-top:20px;
}

button.hiddenButton:disabled{
    background-color: white !important;
}

.straumannBlueButton {
    border: 1px solid #467abd;
    background-color: #467abd;
    color: #fff;

    .ms-Icon {
        color: #fff;
    }

    &:hover {
        color: #fff;
        background-color: #2462A2;

        .ms-Icon {
            color: #fff;
        }
    }

    &:disabled {
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #666666;
    }
}

.ms-Nav-navItem i{
    color:red;
    font-weight: bold;
  }
  
.RSMSummary {
    input.ms-TextField-field::placeholder {
        color: white !important;
    }

    .ms-TextField-errorMessage, .rsmError{
        display:none;
    }
}

.stepWizard > div {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 140px);
}

.stepWizard .ms-FocusZone {
    overflow: visible;
}

.stepWizard > div > div {
    padding-left: 5px;
    padding-right: 15px;
    padding-bottom: 10px;
}

.tableStyle .ms-DetailsHeader{
    padding-top: 0px;
}

.ms-Dialog-inner{
    padding-bottom:0px;
}

span.ms-Dropdown-title.ms-Dropdown-titleIsPlaceHolder {
    font-style: italic !important;
    color: #c7c7c7 !important;
}
fieldset{
    margin-top:20px !important;
}
.lineHeight {
    line-height: 50px;
}
