@import "~office-ui-fabric-react/dist/sass/References.scss";

.myHeader {
    color: red;
}


.gSignInWrapper {
    border: 1px solid #888;
    padding: 20px;
}
