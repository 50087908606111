.welcomeHeading {
    font-size: 1.5rem;
    color: #467abd;
    margin: 0 0 20px 0;
}

.heading {
    font-weight: 600;
    font-size: 24px;
}

.linkButton {
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
}

.actionButtonContainer {
    width: 50%;
    padding: 10px;
}

input[type='text']::placeholder, textarea::placeholder {
    font-style: italic !important;
    color: #c7c7c7 !important;
}

.flagButton {
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    width: 200px;
    height: 100px;

    &:active,
    &:hover,
    &:focus {
        border: none;
    }

    .flag {
        width: 100%;
        height: 100%;
    }

    @media screen and (max-width: 480px) {
        width: 100px;
        height: 50px;
    }
}


.animate {
    animation-duration: .8192s;
    animation-fill-mode: backwards;
    transform-style: preserve-3d;
}

/** intro */
@keyframes stepIntro {
    from {
        opacity: 0.3;
        transform: perspective(820px) translate3d(0, 0, -20px);        
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.stepPopIn {
    animation: stepIntro 1s ease-out;
}

.stepWizard {
    display: flex;
    width: 100%;

    .rsw_2Y {
        margin-left: 20px;
        width: 100%;
    }
}

.inlineflex .ms-ChoiceField {
    display: inline-block;
    margin: 0 0 0 10px;
    padding: 5px 0;
}

legend {
    font-size: 14px;
    font-weight: 600;
}

.titleLabel {
    font-weight: bold;
}
.marginTop{
    margin-top:20px !important;
}

.actionButtonsNavigationBar {
    & > button {
        margin-left: 20px;

        &:first-child {
            margin-left: 0;
        }
    }

    @media screen and (max-width: 480px) {
        // width: 100%;
        display: flex;
        // justify-content: space-between;
        &:last-child {
            margin-top: 40px;
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
    }
}

.wizardNavigationContainer {

    .wizardButton {
        border: 1px solid black;

        .ms-Icon {
            color: #323130;
        }

        &:hover {
            .ms-Icon {
                color: #0078d4;
            }
            // border: 1px solid #0078d4;
        }
    }

    @media screen and (max-width: 480px) {
        width: 100%;
    }
}

.text {
    font-weight: normal;
}
.goToday.goTodayInlineMonth.goToTodayIsDisabled {
    top: 240px;
}

.holder {
    height: 270px;
}
.smallLabel{
    font-weight:400;
    color:dimgrey;
}